import { render, staticRenderFns } from "./menus.vue?vue&type=template&id=518f1fa3&scoped=true"
import script from "./menus.vue?vue&type=script&lang=js"
export * from "./menus.vue?vue&type=script&lang=js"
import style0 from "./menus.vue?vue&type=style&index=0&id=518f1fa3&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "518f1fa3",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src182499851/src/clients/ap3/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('518f1fa3')) {
      api.createRecord('518f1fa3', component.options)
    } else {
      api.reload('518f1fa3', component.options)
    }
    module.hot.accept("./menus.vue?vue&type=template&id=518f1fa3&scoped=true", function () {
      api.rerender('518f1fa3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/sites/brand/overview/menus.vue"
export default component.exports